/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { StaticQuery, graphql } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink";



import Navbar from "./navBar"
import Foot from "./footer"

const Layout = ({ children, pageInfo }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
      <Navbar>
        <li><AniLink fade to="/"><img src="/images/ANDlogo.png" alt="AND logo"/></AniLink></li>
      </Navbar>
    

        {children}
    <Foot/>
      </>
    )}
  />
)

export default Layout
