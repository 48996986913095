import React from "react"
import { StaticQuery, graphql } from 'gatsby'
import AniLink from "gatsby-plugin-transition-link/AniLink";


export default ({children}) => (
   <StaticQuery
   query={graphql`
    query NavQuery{
      allStrapiAndcat {
        edges {
          node {
            id
            cname
          }
        }
      }
    }
   `}
  
  render={ data => (
    <>
    
      <div className="l-nav">
           <div className="l-nav-links">
               <ul className="l-nav-list">
                   
                   <li><AniLink fade to="/gallery">gallery</AniLink></li>
                     {data.allStrapiAndcat.edges.map(document => (
                 <li key={document.node.id}>
                   <AniLink fade to={`/${document.node.cname}`}>{document.node.cname}</AniLink>
                 </li>
                
               ))}
                    {children}
                 
               </ul>
           </div>
        </div>
    </>
  )
}
/>
)
