
import React from "react"
import {Container, Row,Col} from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter ,faInstagram,faLinkedin} from '@fortawesome/free-brands-svg-icons'
import AniLink from "gatsby-plugin-transition-link/AniLink";


const Footer = () => (
<footer className="page-footer font-small">
<Container>
    <Row>
        <Col md={12} className="my-4 mt-2">
            <div className="text-center ">
                <a href="https://twitter.com" className="">
                 <FontAwesomeIcon icon={faTwitter} size="lg" className=" white-text  smicon"/>   
                </a>
                <a href="https://linkedin.com" className="">
                 <FontAwesomeIcon icon={faLinkedin} size="lg" className=" white-text smicon "/>   
                </a>
                <a href="https://instagram.com" className="" >
                 <FontAwesomeIcon icon={faInstagram} size="lg" className=" white-text smicon" />   
                </a>
            </div>
        </Col>
    </Row>
< /Container>
<div className="footer-copyright text-center py-3">©
<AniLink fade to="/">ANDDesignstudio</AniLink> <span id="year">{(new Date().getFullYear())}</span>

</div>
</footer>

)

export default Footer